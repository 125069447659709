import { AxiosError } from "axios";

import api from "./apiClient";
import { SearchPaginationQuery, downloadResponseAsJSON } from ".";
import {
    Address,
    Carrier,
    DeliveryTask,
    DeliveryTaskStatus,
    ListResponse,
    LoadingTask,
    LoadingTaskEvent,
    LoadingTaskStatus,
    Store,
    TaskType,
    TourOrder,
    TourOrderDriverType,
    TourOrderStatus,
    Trace,
    Trailer,
    TrailerDepotTask,
    TrailerPickupTask,
    User,
    ValueListItem,
} from "./types";

export type TourOrderIdPayload = TourOrder["id"];
export type TourOrdersListPayload = SearchPaginationQuery & {
    from?: string;
    to?: string;
    status?: TourOrder["status"] | Array<TourOrder["status"]>;
    store?: Store["id"];
    carrier?: Carrier["id"];
    driver?: User["id"];
    activity?: ValueListItem["id"];
    withoutTrailer?: boolean;
    fresh?: boolean;
};

export enum TrailerType {
    temporary = "temporary",
    registered = "registered",
}

export type CreateTourOrderPayload = {
    createdBy: User["id"];
    status: TourOrderStatus.readyToStart;
    date: string;
    driver: User["id"];
    driverType: TourOrderDriverType;
    truckRegistration: string;
    trailer: {
        type: TrailerType;
        trailer?: Trailer["id"];
        registration?: string;
        location?: Trailer["location"];
    };
    scheduledDeparture: string;
    scheduledEndTime: string;
    expectedDistance: number;
    stores: Array<Store["id"]>;
    platform: [];
    tasks: {
        loadingTasks: Array<{
            index: number;
            initialStartTime: string;
            startTime: string;
            type: TaskType.loading;
            status: LoadingTaskStatus.todo;
            warehouse: ValueListItem["id"];
            numberOfPalletsToLoad: number;
            loadingItems: Array<{
                store: Store["id"];
                pallets: Array<{
                    product: ValueListItem["id"];
                    quantity: number;
                    orders: Array<string>;
                }>;
            }>;
            address: Address;
            events: Array<LoadingTaskEvent>;
        }>;
        deliveryTasks: Array<{
            index: number;
            type: TaskType.delivery;
            status: DeliveryTaskStatus.todo;
            initialStartTime: string;
            startTime: string;
            store: Store["id"];
            numberOfPallets: number;
            address: Address;
        }>;
    };
};

export enum AssignTrailerType {
    registered = "registered",
    temporary = "temporary",
}
export interface AssignTrailerPayload {
    tourOrderId: TourOrder["id"];
    type: AssignTrailerType;
    trailer?: Trailer["id"];
    registration?: Trailer["registration"];
    location?: ValueListItem["id"];
}
export interface ChangeTruckPayload {
    tourOrderId: TourOrder["id"];
    truckRegistration: TourOrder["truckRegistration"];
}
export interface AddCommentPayload {
    tourOrderId: TourOrder["id"];
    operationComment?: string | null;
    shippingServiceComment?: string | null;
}
export interface AssignYardDriverPayload {
    tourOrderId: TourOrder["id"];
    taskId: string;
    yardDriver: User["id"] | null;
}

export interface AssignDriverPayload {
    tourOrderId: TourOrder["id"];
    userId: User["id"];
    driverType: TourOrderDriverType;
}

export interface ForcedTrailerPermissionPayload {
    tourOrderId: TourOrder["id"];
    trailerPickupTaskId: TrailerPickupTask["id"];
    accepted: boolean;
}

export interface LoadingForcedPlatformPermissionPayload {
    tourOrderId: TourOrder["id"];
    loadingTaskId: LoadingTask["id"];
    accepted: boolean;
    tourOrder?: TourOrder["id"];
    location?: ValueListItem["id"];
}

export interface TrailerDepotForcedPlatformPermissionPayload {
    tourOrderId: TourOrder["id"];
    trailerDepotTaskId: TrailerDepotTask["id"];
    accepted: boolean;
    tourOrder?: TourOrder["id"];
    location?: ValueListItem["id"];
}

export interface TrailerDepotForcedDepotZonePermissionPayload {
    tourOrderId: TourOrder["id"];
    trailerDepotTaskId: TrailerDepotTask["id"];
    accepted: boolean;
    location?: ValueListItem["id"];
}

export interface TracePayload {
    tourOrderId: TourOrder["id"];
    originalPoint?: boolean;
}

export interface AssignTrailerDepotLocationPayload {
    tourOrderId: TourOrder["id"];
    trailerDepotTaskId: TrailerDepotTask["id"];
    location?: ValueListItem["id"];
    tourOrder?: TourOrder["id"];
}

export interface ValidateUnloadedSupportsPayload {
    tourOrderId: TourOrder["id"];
    deliveryTasks: Array<{
        id: DeliveryTask["id"];
        returnSupports: Array<{
            support: string;
            returnedQuantity: number;
        }>;
    }>;
}

export type ValidateUnloadedSupportsError = AxiosError<
    | {
          error: "tourOrderNotFound" | "invalidTourOrderStatus" | "deliveryTaskNotFound" | "supportNotFound";
      }
    | undefined
>;

export type TourOrdersListResponse = ListResponse<TourOrder>;
export type TourOrderDetailResponse = TourOrder;
export type AssignTrailerResponse = TourOrder;

export type AssignTrailerError = AxiosError<
    | {
          fields: {
              registration: Trailer["registration"];
          };
      }
    | undefined
>;

export enum HazardTaskDeclaration {
    during = "during",
    before = "before",
}

// Routes
export const create = async (payload: CreateTourOrderPayload) => {
    return await api.post<TourOrder>(`/tourOrders`, payload).then((response) => response?.data);
};

export const list = async (payload?: TourOrdersListPayload) => {
    return await api.get<TourOrdersListResponse>(`/tourOrders`, { params: payload }).then((response) => response?.data);
};

export const details = async (id?: TourOrderIdPayload) => {
    return await api.get<TourOrderDetailResponse>(`/tourOrders/${id}`).then((response) => response?.data);
};

export const assignTrailer = async ({ tourOrderId, ...payload }: AssignTrailerPayload) => {
    return await api
        .post<AssignTrailerResponse>(`/tourOrders/${tourOrderId}/trailer`, payload)
        .then((response) => response?.data);
};

export const changeTruck = async ({ tourOrderId, ...payload }: ChangeTruckPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/truck`, payload).then((response) => response?.data);
};

export const addComment = async ({ tourOrderId, ...payload }: AddCommentPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/comment`, payload).then((response) => response?.data);
};

export const cancelTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.post<TourOrder>(`/tourOrders/${tourOrderId}/cancel`).then((response) => response?.data);
};

export const assignYardDriver = async ({ tourOrderId, taskId, ...payload }: AssignYardDriverPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/loadingTasks/${taskId}/yardDriver`, payload)
        .then((response) => response?.data);
};

export const assignDriver = async ({ tourOrderId, ...payload }: AssignDriverPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/assignDriver`, payload).then((response) => response?.data);
};

export const forcedTrailerPermission = async ({
    tourOrderId,
    trailerPickupTaskId,
    ...payload
}: ForcedTrailerPermissionPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/trailerPickupTasks/${trailerPickupTaskId}/forcedTrailerPermission`, payload)
        .then((response) => response.data);
};

export const loadingForcedPlatformPermission = async ({
    tourOrderId,
    loadingTaskId,
    ...payload
}: LoadingForcedPlatformPermissionPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/loadingTasks/${loadingTaskId}/forcedPlatformPermission`, payload)
        .then((response) => response.data);
};

export const trailerDepotforcedPlatformPermission = async ({
    tourOrderId,
    trailerDepotTaskId,
    ...payload
}: TrailerDepotForcedPlatformPermissionPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/trailerDepotTasks/${trailerDepotTaskId}/forcedDepotZonePermission`, payload)
        .then((response) => response.data);
};

export const trailerDepotforcedDepotZonePermission = async ({
    tourOrderId,
    trailerDepotTaskId,
    ...payload
}: TrailerDepotForcedDepotZonePermissionPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/trailerDepotTasks/${trailerDepotTaskId}/forcedDepotZonePermission`, payload)
        .then((response) => response.data);
};

export const trace = async ({ tourOrderId, originalPoint }: TracePayload) => {
    return await api
        .get<Trace>(`/tourOrders/${tourOrderId}/trace`, { params: { originalPoint } })
        .then((response) => response.data);
};

// Super admin routes
export const updateDateOfTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/updateDateOfTourOrder`).then((response) => response.data);
};

export const downloadTourOrderBody = async (tourOrderId: TourOrderIdPayload) => {
    return await api
        .get<Blob>(`/tourOrders/${tourOrderId}/downloadBody`, {
            responseType: "blob",
        })
        .then((response) => {
            downloadResponseAsJSON(response);
            return null;
        });
};

export const deleteTourOrder = async (tourOrderId: TourOrderIdPayload) => {
    return await api.delete(`/tourOrders/${tourOrderId}`).then((response) => response.data);
};

export const sendUpdateToDriver = async (tourOrderId: TourOrderIdPayload) => {
    return await api.post(`/tourOrders/${tourOrderId}/sendUpdateToDriver`).then((response) => response.data);
};

export const assignTrailerDepotLocation = async ({
    tourOrderId,
    trailerDepotTaskId,
    ...payload
}: AssignTrailerDepotLocationPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/trailerDepotTasks/${trailerDepotTaskId}/assignLocation`, payload)
        .then((response) => response.data);
};

export const validateUnloadedSupports = async ({ tourOrderId, ...payload }: ValidateUnloadedSupportsPayload) => {
    return await api
        .post(`/tourOrders/${tourOrderId}/validateUnloadedSupports`, payload)
        .then((response) => response.data);
};
